import React, { ReactElement } from 'react';
import styles from './Banner.module.scss';
import { Carousel, ICarouselOptions } from 'components/ui/Carousel/Carousel';
import { MainBannerImage } from 'components/pages/Home/Banner/MainBannerImage/MainBannerImage';
import { MainBannerImage as MainBannerImageBel }
  from 'components/pages/Home/Banner/MainBannerImage.bel/MainBannerImage.bel';
import { MainBannerImage as MainBannerImageSakhalin }
  from 'components/pages/Home/Banner/MainBannerImage.sakhalin/MainBannerImage.sakhalin';
import { MainBannerImage as MainBannerImageSimple }
  from 'components/pages/Home/Banner/MainBannerImage.simple/MainBannerImage.simple';
import { BannerImage } from 'components/ui/BannerImage/BannerImage';
import { CustomButtonGroup } from 'components/ui/Carousel/Buttons/ButtonGroup';
import { IBanner, IBannerPick, IPicks } from '@common/redux/types/picks';
import { themeValue } from "@kursk/themes";

export interface IBannerWrapperProps {
  banners: IPicks[];
  withMainBanner?: boolean;
  noBannerMode?: boolean;
}


export function Banner({ banners, withMainBanner, noBannerMode }: IBannerWrapperProps): ReactElement {
  const prepareBanners: IBanner[] = (banners as unknown as IBannerPick[])
    .map(({ banner }) => banner).filter(({ enabled }) => enabled);

  const renderItem = (item: IBanner) => {
    return (
      <a key={item.url} href={item.url} target='_blank' rel="noreferrer">
        <BannerImage
          desktopImage={item?.desktopImage}
          tabletImage={item?.tabletImage}
          mobileImage={item?.mobileImage}
        />
      </a>);
  }

  const isNeed = (withMainBanner && prepareBanners.length >= 1) || (prepareBanners.length > 1);

  const addMainBanner = noBannerMode ? prepareBanners.length === 0 : withMainBanner;

  const mainBanner = themeValue({
    bel: <MainBannerImageBel />,
    sakhalin: <MainBannerImageSakhalin />,
    kursk: <MainBannerImage />,
    default: <MainBannerImageSimple />,
  });

  const options: ICarouselOptions = {
    infinite: true,
    autoPlay: isNeed,
    autoPlaySpeed: 5000,
  };

  return (
    <div className={styles.bannerWrapper}>
      {prepareBanners.length ? (
        !addMainBanner && prepareBanners.length === 1 ?
          <>
            {prepareBanners.map(renderItem)}
          </> : (
            <Carousel
              Buttons={isNeed && <CustomButtonGroup/>}
              carouselOptions={options}
            >
              {addMainBanner && mainBanner}
              {prepareBanners.map(renderItem)}
            </Carousel>
          )
      ) : withMainBanner ? mainBanner : null}
    </div>
  );
}
