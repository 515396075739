import React, { ReactElement } from 'react';
import { regionContent } from "@kursk/content/regionContent";
import styles from './WeatherCopyright.module.scss';

export function WeatherCopyright(): ReactElement {
  return (
    <a
      className={styles.text}
      href={regionContent.widgetWeather?.weatherServiceLink}
      target="_blank"
      rel="noreferrer"
    >
      Данные о погоде предоставлены сайтом
      <span className={styles.highlighted}>{regionContent.widgetWeather?.weatherServiceLabel}</span>
    </a>
  );
}
