import React, { ReactElement } from 'react';
import { themeValue } from "@kursk/themes";
import { VideoContentSochi } from "./VideoContent.sochi/VideoContent.sochi";

export function VideoContent(): ReactElement {

  return themeValue({
    sochi: <VideoContentSochi />,
    default: <></>,
  });
}
