import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { IWeatherData } from '@common/redux/types/widgetWeather';
import styles from './WeatherBlock.module.scss';

interface IProps {
  tooltip: string;
  icon: any;
  weatherData: IWeatherData;
}

export function WeatherBlock({
  weatherData = { temperature: '–', description: '–' },
  tooltip,
  icon
}: IProps): ReactElement {
  const titleStyles = [
    styles.weatherElementTitle,
    parseInt(weatherData.temperature) === 0 && styles.weatherElementTitleExtraPadding,
  ]

  const renderTemperature = () => {
    if (weatherData.temperature === '–') return weatherData.temperature;
    let value = weatherData.temperature;
    if (parseInt(weatherData.temperature) > 0) {
      value = `+${weatherData.temperature}`.replace('++', '+');
    } else if (parseInt(weatherData.temperature) < 0) {
      value = weatherData.temperature.replace('-', '–');
    }
    return (
      <>
        {value}
        <span className={styles.weatherElementTitleTemperature}>°c</span>
      </>
    );
  }

  return (
    <div className={styles.weatherElement} title={tooltip}>
      <div className={classNames(titleStyles)}>
        {renderTemperature()}
      </div>
      <div className={styles.descriptionWrap}>
        {icon}
        <div className={styles.weatherElementSubTitle}>
          {weatherData.description}
        </div>
      </div>
    </div>
  );
}
