import React, { ReactElement } from 'react';
import { useSelectSlice } from '@common/redux/selectors/useSelectSlice';
import { picksSlice } from '@common/redux/slices/picks';
import { EventCard } from './EventCard/EventCard';
import { useOrientations } from "@common/hooks/useOrientation";
import styles from './BannerWidgetEvents.module.scss';

export function BannerWidgetEvents(): ReactElement {
  const orientation = useOrientations();
  const { best } = useSelectSlice(picksSlice);
  const itemsCount = orientation === 'mobile' ? 1 : 2;

  const eventsToShow = best.slice(4).filter(({ type }) => type === 'event').slice(0, itemsCount);

  return (
    <div className={styles.eventsWrapper}>
      {eventsToShow.map((item) => (
        <EventCard key={item.event._id} event={item.event} />
      ))}
    </div>
  );
}
