import React, { ReactElement } from 'react';
import { ISrcSet, Picture } from '../Picture/Picture';
import styles from './BannerImage.module.scss';
import { IImage } from "@common/redux/types/images";

interface IBannerProps {
  desktopImage: IImage;
  tabletImage: IImage;
  mobileImage: IImage;
}

export function BannerImage({ desktopImage, mobileImage, tabletImage }: IBannerProps): ReactElement {

  const srcSet: ISrcSet[] = [
    {
      media: "(min-width: 1200px)",
      width: 1200,
      height: 500,
      image: desktopImage,
    },
    {
      media: "(min-width: 768px) and (max-width: 1199px)",
      width: 1199,
      height: 624,
      image: tabletImage,
    },
    {
      media: "(max-width: 767px)",
      width: 768,
      height: 409,
      image: mobileImage,
    },
  ];

  return (
    <Picture className={styles.wrapper} srcSet={srcSet} />
  );
}
