import { regionContent } from '@kursk/content/regionContent';
import React, { ReactElement } from 'react';
import nl2br from 'react-nl2br';
import styles from '@kursk/components/pages/Home/Banner/MainBannerImage.sakhalin/MainBannerImage.sakhalin.module.scss';
import { useOrientations } from '@common/hooks/useOrientation';

export function MainBannerImage(): ReactElement {
  const orientation = useOrientations();

  const prepareText = (text: string) => orientation === 'tablet' ? text.split('\n').join(' ') : nl2br(text);

  // eslint-disable-next-line max-len
  const text = 'Сахалин и Курилы — это море и озера, вулканы и пещеры,\nкраснокнижные животные и редкие растения.\nНо ещё это и край с интересной историей. Эти территории\nв разное время были японскими и китайскими, и это сделало\nкультуру региона особенной.';
  // eslint-disable-next-line max-len
  const mobileText = 'Сахалин и Курилы — это море и озера, вулканы\nи пещеры,краснокнижные животные и редкие\nрастения. Но ещё это и край с интересной\nисторией. Эти территории в разное время\nбыли японскими и китайскими, и это\nсделало культуру региона особенной.';

  return (
    <div className={styles.wrapper}>
      <picture>
        <source srcSet='/images/main-banner.png' media='(min-width: 1200px)' />
        <source srcSet='/images/main-banner-tablet.png' media='(min-width: 768px) and (max-width: 1199px)' />
        <source srcSet='/images/main-banner-mobile.png' media='(max-width: 767px)' />
        <img className={styles.wrapper} srcSet='/images/main-banner.png' />
      </picture>
      <div className={styles.content}>
        <h1 className={styles.title}>{nl2br(regionContent.mainBanner.title)}</h1>
        <p className={styles.paragraph}>{prepareText(orientation === 'mobile' ? mobileText : text)}</p>
      </div>
    </div>
  );
}
