import React, { ReactElement } from 'react';
import { BannerWidgetDate } from 'components/ui/BannerWidgetDate/BannerWidgetDate';
import { BannerWidgetWeather } from 'components/ui/BannerWidgetWeather/BannerWidgetWeather';
import { WeatherCopyright } from 'components/ui/BannerWidgetWeather/WeatherCopyright/WeatherCopyright';
import { BannerWidgetEvents } from 'components/ui/BannerWidgetEvents/BannerWidgetEvents';
import styles from './VideoContent.sochi.module.scss';

export function VideoContentSochi(): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>Начни свой отдых в Сочи</div>
        <div className={styles.widgetsWrapper}>
          <BannerWidgetDate />
          <BannerWidgetWeather />
        </div>
        <WeatherCopyright />
      </div>
      <div className={styles.bottomBlock}>
        <BannerWidgetEvents />
      </div>
    </div>
  );
}
