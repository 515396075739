import React, { ReactElement } from 'react';
import { VideoContent } from "./VideoContent/VideoContent";
import { useOrientations } from "@common/hooks/useOrientation";
import styles from './Video.module.scss';

export function Video(): ReactElement {
  const orientation = useOrientations();
  let posterUrl = '/video_page_index_poster.png';

  switch (orientation) {
    case "mobile":
      posterUrl = '/video_page_index_poster_mobile.png';
      break;
    case "tablet":
      posterUrl = '/video_page_index_poster_tablet.png';
      break;
  }
  return (
    <div className={styles.videoWrapper}>
      <div className={styles.videoContentWrap}>
        <video poster={posterUrl} autoPlay playsInline muted loop disablePictureInPicture>
          <source src="/video_page_index.webm" type="video/webm"/>
          <source src="/video_page_index.mp4" type="video/mp4"/>
        </video>
        <VideoContent />
      </div>
    </div>
  );
}
