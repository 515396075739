import React, { ReactElement } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import { IEvent } from '@common/redux/types/event';
import { isTicketsInfoAvailable } from '@common/utils/tickets';
import { getPriceTitle } from "@kursk/utils/getPriceTitle";
import { useOrientations } from "@common/hooks/useOrientation";
import styles from './EventCard.module.scss';

interface IEventCardProps {
  event: IEvent;
}

const isEipskPricesExists = (event: IEvent) =>
  !!event.eipskPrices && (!_.isNull(event.eipskPrices.min) || !_.isNull(event.eipskPrices.max));

export function EventCard({ event }: IEventCardProps): ReactElement {
  const orientation = useOrientations();

  const prepareDate = (): string => {
    const format = orientation === 'mobile' ? 'DD MMM YYYY' : 'DD MMMM YYYY';
    const start = moment(event?.actualSeances?.nearest?.periodStart);
    const end = moment(event?.actualSeances?.nearest?.periodEnd);

    const isSeance = event.type === 'seances';

    if (isSeance) {
      return start.format(format);
    } else {
      const now = moment();
      const currentStart = start.valueOf() < now.valueOf() ? now : start;
      const startFormatted = currentStart.format(format);
      const endFormatted = end.format(format);

      return startFormatted == endFormatted ? endFormatted : `${startFormatted} - ${endFormatted}`.toLowerCase();
    }
  };

  const getPriceInfo = (): string | JSX.Element => {
    if (event.isFree) {
      return 'Бесплатно';
    }

    const { all } = event?.actualSeances || {};
    const { schedules } = event;
    const { eipskPrices: { max, min } = {} } = event;
    const now = moment();

    const allShedules = all?.length ? all : _.filter(schedules, ({ end }) => moment(end).isAfter(now));

    const availableTicketsInfos = _(allShedules).map('ticketsInfo')
      .filter((ticketsInfo) => isTicketsInfoAvailable(ticketsInfo))
      .value();

    const maxPrice = _(availableTicketsInfos).map('maxPrice').max();
    const minPrice = _(availableTicketsInfos).map('minPrice').min();

    if (!_.isUndefined(minPrice) && !_.isUndefined(maxPrice)) {
      return getPriceTitle(minPrice, maxPrice);
    }


    if (isEipskPricesExists(event)) {
      return getPriceTitle(min * 100, max * 100);
    }

    return '';
  }

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 300,
      height: 200,
    },
    {
      media: '(max-width: 767px)',
      width: 210,
      height: 140,
    },
  ];

  return (
    <Link href={`/events/${event._id}/${event.sysName}`} passHref>
      <a className={styles.link}>
        <div className={styles.eventElement}>
          <div className={styles.imageWrapper}>
            <Picture className={styles.image} srcSet={imgSrcSet} image={event.image}/>
          </div>
          <div className={styles.infoWrapper}>
            <div>
              <div className={styles.date}>{prepareDate()}</div>
              <LinesEllipsis
                text={event.name}
                maxLine={orientation === 'mobile' ? 2 : 3}
                ellipsis='...'
                trimRight
                basedOn='letters'
                className={styles.name}
              />
            </div>
            <div className={styles.priceWrapper}>
              {getPriceInfo()}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
