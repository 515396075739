import React, { ReactElement } from 'react';
import { getComponentVersion } from "@kursk/utils/componentsTemplates";
import { Page } from "@kursk/components/seo/seo.types";
import { Banner, IBannerWrapperProps } from './Banner/Banner';
import { Video } from './Video/Video';

const componentsHash = {
  banner: Banner,
  video: Video,
}

interface HeroBlockProps extends IBannerWrapperProps {
  page?: Page,
}

export function HeroBlock({ page, ...props }: HeroBlockProps): ReactElement {

  const componentVersion = getComponentVersion({ componentType: 'heroBlock', page });
  const Component = componentsHash[componentVersion];

  return <Component {...props} />
}
