import React, { ReactElement, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { regionContent } from "@kursk/content/regionContent";
import { useOrientations } from "@common/hooks/useOrientation";
import styles from './BannerWidgetDate.module.scss';

export function BannerWidgetDate(): ReactElement {
  const orientation = useOrientations();
  const timeZone = regionContent.timezone;
  const [currentTime, setCurrentTime] = useState(moment.tz(timeZone).valueOf());
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setCurrentTime(moment.tz(timeZone).valueOf());
    }, 1000)

    return () => {
      clearInterval(interval.current);
    }
  }, []);

  return orientation === 'mobile' ? (
    <div className={styles.widgetElementMobile}>
      {moment.tz(currentTime, timeZone).format('HH:mm dddd, DD MMMM')}
    </div>
  ) : (
    <div className={styles.widgetElement}>
      <div className={styles.widgetElementTitle}>
        {moment.tz(currentTime, timeZone).format('HH:mm')}
      </div>
      <div className={styles.widgetElementSubTitle}>
        <span>{moment.tz(currentTime, timeZone).format('DD MMMM')}</span>
        <span>{moment.tz(currentTime, timeZone).format('dddd')}</span>
      </div>
    </div>
  );
}
