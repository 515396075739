import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useSelectSlice } from '@common/redux/selectors/useSelectSlice';
import { loadWeatherThunk, widgetWeatherSlice } from "@common/redux/slices/widgetWeather.slice";
import { MountIcon, BeachIcon } from "@kursk/components/ui/icons";
import { WeatherBlock } from "./WeatherBlock/WeatherBlock";
import { regionContent } from "@kursk/content/regionContent";
import styles from './BannerWidgetWeather.module.scss';

export function BannerWidgetWeather(): ReactElement {
  const dispatch = useDispatch();
  const weatherData = useSelectSlice(widgetWeatherSlice);

  const loadWeather = () => {
    dispatch(loadWeatherThunk({ city: regionContent.widgetWeather?.city }));
  }

  useEffect(() => {
    loadWeather();
  }, []);

  return (
    <div className={styles.widgetWrap}>
      <WeatherBlock
        weatherData={weatherData.data.mountains}
        icon={MountIcon()}
        tooltip="Красная Поляна"
      />
      <WeatherBlock
        weatherData={weatherData.data.sea}
        icon={BeachIcon()}
        tooltip="Сочи"
      />
    </div>
  );
}
