import { regionContent } from '@kursk/content/regionContent';
import React, { ReactElement } from 'react';
import nl2br from 'react-nl2br';
import { Button } from 'components/ui/Button/Button';
import styles from '@kursk/components/pages/Home/Banner/MainBannerImage/MainBannerImage.module.scss';

export function MainBannerImage(): ReactElement {
  const prepareText = (text: string) => {
    return nl2br(text.split('. ').join('.\n'));
  }

  return (
    <div className={styles.wrapper}>
      <img className={styles.wrapper} srcSet='/images/main-banner.jpg'/>
      <div className={styles.content}>
        <h1 className={styles.title}>{regionContent.mainBanner.title}</h1>
        <p className={styles.paragraph}>{prepareText(regionContent.mainBanner.text)}</p>
      </div>
      <div className={styles.buttons}>
        {regionContent.mainBanner?.links[0] &&
          <Button className={styles.buttonWhatToSee} href={regionContent.mainBanner.links[0].link}>
            {regionContent.mainBanner.links[0].text}
          </Button>
        }
        {regionContent.mainBanner?.links[1] &&
          <Button className={styles.buttonWhatToVisit} href={regionContent.mainBanner.links[1].link}>
            {regionContent.mainBanner.links[1].text}
          </Button>
        }
        {regionContent.mainBanner?.links[2] &&
          <Button className={styles.buttonWhereToEat} href={regionContent.mainBanner.links[2].link}>
            {regionContent.mainBanner.links[2].text}
          </Button>
        }
      </div>
    </div>
  );
}
