import { regionContent } from '@kursk/content/regionContent';
import React, { ReactElement } from 'react';
import nl2br from 'react-nl2br';
import styles from './MainBannerImage.bel.module.scss';
import { useOrientations } from "@common/hooks/useOrientation";

export function MainBannerImage(): ReactElement {
  const orientation = useOrientations();
  const prepareText = (text: string) => {
    return nl2br(text.split('. ').join('.\n'));
  }

  return (
    <div className={styles.wrapper}>
      {(orientation === 'desktop' || orientation === 'tablet') && (
        <img className={styles.wrapper} srcSet='/images/main-banner.png'/>
      )}
      {orientation === 'mobile' && (
        <img className={styles.wrapper} srcSet='/images/main-banner-min.png'/>
      )}
      <div className={styles.content}>
        <h1 className={styles.title}>{regionContent.mainBanner.title}</h1>
        <p className={styles.paragraph}>{prepareText(regionContent.mainBanner.text)}</p>
      </div>
    </div>
  );
}
