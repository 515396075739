import React, { ReactElement } from 'react';
import styles from './MainBannerImage.simple.module.scss';
import { useOrientations } from "@common/hooks/useOrientation";

export function MainBannerImage(): ReactElement {
  const orientation = useOrientations();
  return (
    <div className={styles.wrapper}>
      <picture>
        <source srcSet='/images/main-banner.png' media='(min-width: 1200px)'/>
        <source srcSet='/images/main-banner-tablet.png' media='(min-width: 768px) and (max-width: 1199px)'/>
        <source srcSet='/images/main-banner-mobile.png' media='(max-width: 767px)'/>

        {orientation !== "mobile" && <img className={styles.wrapper} srcSet='/images/main-banner.png'/>}
        {orientation === "mobile" && <img className={styles.wrapper} srcSet='/images/main-banner-mobile.png'/>}
      </picture>
    </div>
  );
}
